import React from "react";
import { withUnpublishedPreview } from "gatsby-source-prismic";

import Layout from "../components/layout";
import Article from "../components/article";

const NotFoundPage = () => (
  <Layout>
    <h1 className="text-center mt-5">404: Page not found!</h1>
  </Layout>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    article: Article,
    prismicArticle: Article,
  },
});
