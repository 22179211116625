import React from "react";

interface Props {
  image: string;
  caption?: string;
  title?: string;
}

const ContentImage = ({ image, caption, title }: Props) => {
  return (
    <div className="content-image">
      {title && <h3>{title}</h3>}
      <img src={image} alt={caption} className="img-fluid" />
      {caption && <p className="caption">{caption}</p>}
    </div>
  );
};

export default ContentImage;
