import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import React from "react";
import { formatDate } from "../utils/formatDate";
import ContentImage from "./contentimage";
import Layout from "./layout";
import Tag from "./tag";

const Article = ({ data }: any) => {
  const article = data.prismicArticle.data;
  const date = new Date(article.date);
  return (
    <Layout>
      <section className="bg-darkened">
        <div className="container">
          <div className="article">
            <div className="text-div">
              <h2>{article.title.text}</h2>
              <div className="date-author-div">
                <p>
                  {formatDate(date)}{" "}
                  {article.updatedDate ? <i>Updated: {article.date}</i> : ""}
                </p>
                <p className="divider">|</p>
                <p>{article.author.text}</p>
              </div>
            </div>
            <p
              className="pretext"
              dangerouslySetInnerHTML={{
                __html: article.preheader_caption.text,
              }}
            ></p>
            <div className="content-div">
              <p
                dangerouslySetInnerHTML={{
                  __html: article.preheader_text.text,
                }}
              ></p>
            </div>
            <div className="img-div my-5">
              <img
                className="img-fluid"
                src={article.header_image.url}
                alt=""
              />
            </div>
            <p
              className="pretext"
              dangerouslySetInnerHTML={{
                __html: article.header_image_caption.html,
              }}
            ></p>
            <div className="content-div">
              {article.body1.map((item: any) => {
                // If we have a text content slice
                if (item.__typename === "PrismicArticleBody1TextContent") {
                  return (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.primary.text_content.html,
                      }}
                    ></div>
                  );
                }

                // If we have an image and optional caption slice
                if (item.__typename === "PrismicArticleBody1ImageWithCaption") {
                  return (
                    <ContentImage
                      image={item.primary.image1.url}
                      caption={item.primary.caption.text}
                    />
                  );
                }

                // If we have an image with a title
                if (item.__typename === "PrismicArticleBody1ImageWithTitle") {
                  return (
                    <ContentImage
                      image={item.primary.image.url}
                      title={item.primary.title1.text}
                    />
                  );
                }
              })}
            </div>
            <div className="category-container">
              {data.prismicArticle.tags.map((tag: string) => (
                <Tag text={tag} key={tag} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query($uid: String) {
    prismicArticle(uid: { eq: $uid }) {
      uid
      tags
      data {
        title {
          text
        }
        author {
          text
        }
        preheader_caption {
          text
        }
        preheader_text {
          text
        }
        date
        header_image {
          url
        }
        header_image_caption {
          html
        }
        body1 {
          __typename
          ... on PrismicArticleBody1ImageWithCaption {
            id
            primary {
              image1 {
                url
              }
              caption {
                text
              }
            }
          }
          ... on PrismicArticleBody1TextContent {
            id
            primary {
              text_content {
                html
              }
            }
          }
          ... on PrismicArticleBody1ImageWithTitle {
            id
            primary {
              title1 {
                text
              }
              image {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default withPreview(Article);
